import { Modal,show, Button} from 'react-bootstrap';
import React, {useState} from "react";


const API_IMG = "https://image.tmdb.org/t/p/w500"
const MovieBox = ({ original_title, overview, poster_path, id, vote_average, release_date }) => {
    
    const [show, setShow]=useState(false);

    const handleShow=()=>setShow(true);
    const handleClose=()=>setShow(false);
    return (
    <div className="card text-center bg-secondary">
        <div className="movie card-body">
            <img className="card-img-top" src={API_IMG + poster_path} ></img>
            <h3>{original_title}</h3>
            <div className="card-body">
                <button type="button" className="btn btn-danger" onClick={handleShow}>View More</button>
                <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      <img className="card-img-top" style={{width:'14rem'}}src={API_IMG+poster_path} />
                      <h3>{original_title}</h3>
                      <h4>IMDb: {vote_average}</h4>
                      <h5>Release Date: {release_date}</h5>
                      <br></br>
                      <h6>Overview</h6>
                      <p>{overview}</p>
                      </Modal.Body>
                      <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>Close</Button>
                      </Modal.Footer>
                  </Modal>
            </div>
        </div>
    </div>);
}

export default MovieBox;

//{/* <h1> ID: {id}</h1>
     //   <h1>{original_title}</h1>
        
      //  <p>{overview}</p>
        //<p>{id}</p> */}