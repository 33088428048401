import './Movie.css';
import React, { useState, useEffect } from 'react';
import MovieBox from './MovieBox';
import 'bootstrap/dist/css/bootstrap.min.css';

const API_URL = "http://localhost:8080/movie/popular";



// function Movie() {
// 
// const [movies, setMovies] = useState({});
// const [isLoading, setLoading] = useState(true);
// 
// useEffect(() => {
// fetch('http://localhost:8080/movie/popular')
// .then(response => response.json())
// .then(data => {
// setMovies(data.results);
// setLoading(false);
// });
// }, []);
// if (isLoading) {
// return <div className="Movie">Loading...</div>;
// }
// 
// return (<div className="Movie">
//  {/* <ul> */}
// {/* {movies.map((movie) => ( */}
// <li>{movie.original_title}</li>
// ))}
// {/* </ul> */}
//    {/* </div> */}
// )
// }

function Movie() {
    const [movies, setMovies] = useState([]);
    // movies = [];
    useEffect(() => {
        fetch(API_URL)
            .then((res) => res.json())
            .then(data => {
                console.log(data);
                setMovies(data.results)
            })
    }, []);
    const arr = [];
    for (let i = 0; i < movies.length; i++) {
        arr.push(
           //<div className="grid text-center">
                //<div className="movie-container">
                    <MovieBox
                        key={movies[i].id}
                        id={movies[i].id}
                        poster_path={movies[i].poster_path}
                        original_title={movies[i].title}
                        overview={movies[i].overview} 
                        release_date={movies[i].release_date}
                        vote_average={movies[i].vote_average}/>
                //</div>
         //</div>
        )
    }
    return arr;
    // if (movies.length != 0) {
    // return ();
    // } else {
    // return (<div>
    //{/* <h1>Loading</h1> */}
    //{/* </div>); */}
    // }
    // arr;


    // (<div>
    //{/* {movies.map((movie) =><MovieBox key={movie.id} poster_path={movie.poster_path}/>)} */}
    //{/* </div> */}
    // )
}

export default Movie;

    //et movies = 
    // const rows = [];
    // for (let i = 0; i < data.results.length; i++) {
    //     // note: we are adding a key prop here to allow react to uniquely identify each
    //     // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    //     // rows.push(<ObjectRow key={i} />);
    //     rows.push (
    //         <tr key={i}>
    //             <td>{data.results[i].original_title}</td>

    //         </tr>

    //     )
    // }
    // return <tbody>{rows}</tbody>;
    // return data.results.map((movie)=>{
    // return(
    // <tr>
    //{/* { */}
    // movie.map((m)=>{
    // return <td>{m.original_title}</td>
    // })
    //  }
    //{/* </tr> */}
    // )
    // });

    //return JSON.stringify(data);
    // });
    // movies = Promise.resolve(movies);

    //return await movies;